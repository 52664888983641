import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=0b974608&scoped=true&"
import script from "./Banner.vue?vue&type=script&lang=ts&"
export * from "./Banner.vue?vue&type=script&lang=ts&"
import style0 from "./Banner.vue?vue&type=style&index=0&id=0b974608&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b974608",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Decor: require('/vercel/path0/components/Decor.vue').default,SharedHeader: require('/vercel/path0/components/shared/Header.vue').default,SharedRichContent: require('/vercel/path0/components/shared/RichContent.vue').default,SharedButton: require('/vercel/path0/components/shared/Button.vue').default,Container: require('/vercel/path0/components/Container.vue').default})
