var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.componentName,_vm._g(_vm._b({tag:"component",staticClass:"relative rounded-md uppercase font-bold inline-block tracking-wide text-center transition-all disabled:cursor-not-allowed shadow-lg disabled:opacity-60 border-2 border-bronce-500 hover:border-bronce-300",class:{
    'bg-bronce-500 text-white hover:bg-bronce-300 disabled:!border-bronce-500 disabled:bg-bronce-500': _vm.themePrimary,
    'text-bronce-500 dark:text-white hover:bg-bronce-300 hover:text-white -inset-1': _vm.themeSecondary,
    'w-full': _vm.data.fullWidth,
    'lg:text-xs': _vm.sizeSmall,
    'py-3 px-5': _vm.sizeLarge,
    'py-3 px-4 lg:py-2 lg:px-3': _vm.sizeSmall,
  },attrs:{"to":_vm.to,"href":_vm.href,"target":_vm.target,"title":_vm.data.label,"disabled":_vm.disabled}},'component',_vm.$attrs,false),_vm.$listeners),[_c('span',{class:{ 'opacity-0': _vm.loading }},[(_vm.data.icon)?_c('span',{staticClass:"inline-block relative mr-2",class:{
        'text-base -mb-1': _vm.sizeSmall,
        'text-2xl -mb-2 -top-0.5': _vm.sizeLarge,
      }},[_c('NuxtDynamic',{attrs:{"component":`IconsIcon${this.data.icon}`}})],1):_vm._e(),_vm._v("\n    "+_vm._s(_vm.data.label)+"\n  ")]),_vm._v(" "),_c('span',{staticClass:"absolute left-0 top-0 w-full h-full flex justify-center items-center pointer-events-none transition-all duration-300",class:{ 'opacity-0 scale-90': !_vm.loading }},[_c('svg',{staticClass:"animate-spin transition-colors duration-200 h-7 w-7",attrs:{"fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_vm._v(" "),_c('path',{staticClass:"opacity-75",attrs:{"d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z","fill":"currentColor"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }