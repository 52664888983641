
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import {
  ComponentSharedButton,
  Enum_Componentsharedbutton_Size,
  Enum_Componentsharedbutton_Theme,
} from '~/graphql/generated'

@Component
export default class SharedButton extends Vue {
  inheritAttrs = false

  @Prop() data!: ComponentSharedButton

  @Prop() loading: boolean

  get componentName(): string {
    return this.data.link && this.data.link.href.startsWith('/') ? 'nuxt-link' : this.data.link ? 'a' : 'button'
  }

  get href(): string {
    return this.$props.data.link ? this.$props.data.link.href : null
  }

  get to(): string {
    return this.$props.data.link && this.$props.data.link.href.startsWith('/') ? this.$props.data.link.href : null
  }

  get target(): string {
    return this.$props.data.link && this.$props.data.link.target ? this.$props.data.link.target : null
  }

  get themePrimary(): boolean {
    return !this.data.theme || this.data.theme === Enum_Componentsharedbutton_Theme.Primary
  }

  get themeSecondary(): boolean {
    return this.data.theme === Enum_Componentsharedbutton_Theme.Secondary
  }

  get sizeLarge(): boolean {
    return !this.$props.data.size || this.$props.data.size === Enum_Componentsharedbutton_Size.Large
  }

  get sizeSmall(): boolean {
    return this.$props.data.size === Enum_Componentsharedbutton_Size.Small
  }

  get disabled(): boolean {
    return Boolean(this.$attrs.disabled) || this.loading
  }
}
